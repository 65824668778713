import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'

export interface StructureWithBranch extends StructureWithBranchData {
  branch: BranchData
  parents: StructureBase[]
  children: StructureWithBranchData[]
}

interface BranchWardStats {
  total: number
  minimumAchieved: number
  audited: number
  agmHeld: number
}

interface BranchData extends StructureBase {
  wardStats: BranchWardStats
}

interface StructureWithBranchData extends StructureBase {
  branch: BranchData
}

interface BranchResult {
  structure: StructureWithBranch
}

const query = gql`
query getBranchData($type: String!, $code: String!, $childType: String!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    branch {
      wardStats {
        total
        minimumAchieved
        audited
        agmHeld
      }
    }
    parents { type code name userHasAccess }
    children(type: $childType) {
      type
      code
      name
      responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
      branch {
        wardStats {
          total
          minimumAchieved
          audited
          agmHeld
        }
      }
    }
  }
}
`

export default function useBranchData ({ type, code }: StructParams): StructureWithBranch | undefined {
  const childType = getChildType({ type, code })
  const { error, data } = useQuery<BranchResult, QueryStructParams>(
    query, { variables: { type, code, childType } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}
