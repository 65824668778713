import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'

interface PublicityResult {
  structure: StructureWithPublicity
}

interface StructureWithPublicityBase extends StructureBase {
  publicity: PublicitDataForChildren
}

interface PublicitDataForChildren {
  wardStats: PublicityWardStats
  activityCounts: PublicityActivityCounts
}

interface StructureWithPublicity extends StructureWithPublicityBase {
  publicity: PublicityDataForSelf
  parents: StructureBase[]
  children: StructureWithPublicityBase[]
}

interface PublicityDataForSelf extends PublicitDataForChildren {
  columns: PublicityColumns
  wardStats: PublicityWardStats
  activityCounts: PublicityActivityCounts
}

type PublicityColumns = PublicityColumn[]

export interface PublicityColumn {
  field: string
  displayName: string
  targetDesc: string
}

export type PublicityWardStats = PublicityWardStatsField[]

export interface PublicityWardStatsField {
  field: string
  wardsAssigned: number
  wardsTargetAchieved: number
  activityCount: number
}

export type PublicityActivityCounts = PublicityActivityCountsField[]

export interface PublicityActivityCountsField {
  field: string
  target: number
  milestone: number
  count: number
}

const query = gql`
query getPublicityData($type: String!, $code: String!, $childType: String!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    publicity {
      columns {
        field
        displayName
        targetDesc
      }
      wardStats {
        field
        wardsAssigned
        wardsTargetAchieved
        activityCount
      }
      activityCounts {
          field
          target
          milestone
          count
        }
    }
    parents { type code name userHasAccess }
    children(type: $childType) {
      type
      code
      name
      responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
      publicity {
        wardStats {
          field
          wardsAssigned
          wardsTargetAchieved
          activityCount
        }
        activityCounts {
          field
          target
          milestone
          count
        }
      }
    }
  }
}
`

export default function usePublicityData ({ type, code }: StructParams): StructureWithPublicity | undefined {
  const childType = getChildType({ type, code })
  const { error, data } = useQuery<PublicityResult, QueryStructParams>(
    query, { variables: { type, code, childType } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}
