import React, { useCallback } from 'react'

import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, wardNamesCleanup } from '../lib/structures'
import { statsStyle, compareStrings } from '../lib/utils'

import { useUsageReport, UsageReportConstituencyStats, UsageReportWardStats } from '../data/useUsageReport'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import ResponsiblePersons from './ResponsiblePersons'
import { UsageReportStatCell } from './StatCell'

import Table from 'react-bootstrap/Table'
import { Col, Row } from 'react-bootstrap'

import Map from './Map'

import { LoadingSpinner } from './spinners'

import {
  Chart as ChartJS,
  registerables
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'


import { Bar, Doughnut } from 'react-chartjs-2'

ChartJS.register(annotationPlugin, ...registerables)
ChartJS.defaults.font.size = 14

export default function UsageReportView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useUsageReport({ type, code })

  const chartRef = useCallback(chart => {
    if (chart != null) {
      const beforePrintHandler = (): void => {
        chart.resize(400, 200)
      }
      const afterPrintHandler = (): void => {
        chart.resize()
      }
      window.addEventListener('beforeprint', beforePrintHandler)
      window.addEventListener('afterprint', afterPrintHandler)

      return () => {
        window.removeEventListener('beforeprint', beforePrintHandler)
        window.removeEventListener('afterprint', afterPrintHandler)
      }
    }
  }, [])

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  const wardCreatedLabels = [
    { field: 'within7Days', text: 'Within Last 7 Days', color: '#0070c0' },
    { field: 'within30Days', text: 'Within Last 30 Days', color: '#70ad47' },
    { field: 'over30Days', text: 'Over 30 Days', color: '#ff7f00' },
    { field: 'neverCreated', text: 'Never Used', color: '#ff0000' }
  ]

  const constituencyViewedLabels = [
    { field: 'within7Days', text: 'Used Within Last 7 Days', color: '#0070c0' },
    { field: 'within30Days', text: 'Used Within Last 30 Days', color: '#70ad47' },
    { field: 'over30Days', text: 'Used Over 30 Days', color: '#ff7f00' },
    { field: 'neverViewed', text: 'Never (*) Viewed', color: '#ff0000' },
    { field: 'noResponsiblePerson', text: 'No Constituency Head(s) on PRAS', color: '#c00000' }
  ]

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)

  const assignedWards = (stats.usagereport.wards === undefined) ? [] : stats.usagereport.wards.filter(w => w.lastCreated)

  const constituencyVieweMapColor = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return '#ffffff'
    return constituencyViewedLabels.find(x => x.field === c.usagereport.lastViewed)?.color ?? '#ffffff'
  }

  const wardCreatedMapColor = (code: string): string => {
    const w = stats.usagereport.wards.find(w => w.code === code)
    if (w == null) return 'white'
    return wardCreatedLabels.find(x => x.field === w.lastCreated)?.color ?? 'white'
  }

  const provinceViewMapColor = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return 'white'
    return statsStyle(c.usagereport.constituencyStats.within30Days + c.usagereport.constituencyStats.within7Days, c.usagereport.constituencyStats.total).backgroundColor
  }

  const provinceCreatedMapColor = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return 'white'
    return statsStyle(c.usagereport.wardStats.within30Days + c.usagereport.wardStats.within7Days, c.usagereport.wardStats.total).backgroundColor
  }

  const constituencyViewedDailyGraphData = {
    maintainAspectRatio: false,
    datasets: [
      {
        label: 'Usage',
        data: stats.usagereport.constituencyViewedDaily.map(datum => ({ x: datum.date, y: datum.count })),
        backgroundColor: 'orange'
      }

    ]
  }

  const wardCreatedDailyGraphData = {
    maintainAspectRatio: false,
    datasets: [
      {
        label: 'Usage',
        data: stats.usagereport.wardCreatedDaily.map(datum => ({ x: datum.date, y: datum.count })),
        backgroundColor: 'orange'
      }

    ]
  }

  const graphOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          isoWeekday: true,
          unit: 'week'
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      autocolors: true,
      datalabels: {
        display: false
      },
      legend: {
        display: false
      }
    }
  }

    const constituencyViewedPieData: number[] = []
    const constituencyViewedPieLabels: string[] = []
    const constituencyViewedPieColors: string[] = []
    constituencyViewedLabels.forEach(l => {
      constituencyViewedPieData.push(stats.usagereport.constituencyStats[l.field as keyof UsageReportConstituencyStats])
      constituencyViewedPieLabels.push(`${l.text}: ${stats.usagereport.constituencyStats[l.field as keyof UsageReportConstituencyStats]}`)
      constituencyViewedPieColors.push(l.color)
    })



    const wardCreatedPieData: number[] = []
    const wardCreatedPieLabels: string[] = []
    const wardCreatedPieColors: string[] = []
    wardCreatedLabels.forEach(l => {
      wardCreatedPieData.push(stats.usagereport.wardStats[l.field as keyof UsageReportWardStats])
      wardCreatedPieLabels.push(`${l.text}: ${stats.usagereport.wardStats[l.field as keyof UsageReportWardStats]}`)
      wardCreatedPieColors.push(l.color)
    })

    const graphPieOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'left'
        },
        datalabels: {
          color: '#FFFFFF'
        }
      }
    }

    return (
      <>

        <h2 className='d-none d-print-block'>My DA, My Branch Programme of Action</h2>

        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/usagereport' admin />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='usagereport' admin />

        <Row>
          <Col md={6}>
            <h4>Constituency Heads using the Dashboard</h4>
            <div style={{ position: 'relative', height: '200px', width: '100%' }}>
              {/* @ts-expect-error */}
              <Doughnut options={graphPieOptions} data={{
                labels: constituencyViewedPieLabels,
                datasets: [{
                  label: 'Constituency Head(s) using the Dashboard',
                  data: constituencyViewedPieData,
                  backgroundColor: constituencyViewedPieColors,
                  hoverOffset: 4
                }]
              }} plugins={[ChartDataLabels]} ref={chartRef} />
            </div>
          </Col>
          <Col md={6}>
            <h4>Wards using the Activity App</h4>
            <div style={{ position: 'relative', height: '200px', width: '100%' }}>
              {/* @ts-expect-error */}
              <Doughnut options={graphPieOptions} data={{
                labels: wardCreatedPieLabels,
                datasets: [{
                  label: 'Wards using the App in the last 30 days',
                  data: wardCreatedPieData,
                  backgroundColor: wardCreatedPieColors,
                  hoverOffset: 4
                }]
              }} plugins={[ChartDataLabels]} />
            </div>
          </Col>
        </Row>
        <p />

        {type === 'country' ?
          <Row className='dont-break'>
            <Col md={6}>
              <Map type={type} code={code} childStructures={stats.children} childColourFn={provinceViewMapColor} />
            </Col>
            <Col md={6}>
              <Map type={type} code={code} childStructures={stats.children} childColourFn={provinceCreatedMapColor} />
            </Col>
          </Row>
          :
          <Row className='dont-break'>
            <Col md={6}>
              <Map type={type} code={code} childStructures={stats.children} childColourFn={constituencyVieweMapColor} childTypeOverride="constituency" />
          </Col>
          <Col md={6}>
            <Map type={type} code={code} childStructures={assignedWards} childColourFn={wardCreatedMapColor} childTypeOverride="ward" />
          </Col>
          </Row>
        }


        <p />

        <Row className='dont-break'>

          <Col md={6}>
            <div style={{ position: 'relative', height: '400px', width: '90%' }}>
              {/* @ts-expect-error */}
              <Bar options={{
                ...graphOptions,
                plugins: {
                  title: {
                    display: true,
                    text: 'Weekly usage of Dashboard & App by Constituency Head(s)'
                  }
                }
              }} data={constituencyViewedDailyGraphData} />
            </div>
          </Col>
          <Col md={6}>
            <div style={{ position: 'relative', height: '400px', width: '90%' }}>
              {/* @ts-expect-error */}
              <Bar options={{
                ...graphOptions,
                plugins: {
                  title: {
                    display: true,
                    text: 'Weekly activity creation in wards by Public Reps'
                  }
                }
              }} data={wardCreatedDailyGraphData} />
            </div>
          </Col>
        </Row>

        <p />

        <Row className={children.length < 12 ? '' : 'break-before'}>
          <Table responsive striped className='table-sm'>
            <thead>
              <tr>
                <th style={{ width: '30%' }} />
                <th style={{ width: '35%' }} className='text-center'>Use of Dashboard by Constituency Head{type === 'country' ? ' in last 30 days' : ''} </th>
                <th style={{ width: '35%' }} className='text-center'>Wards use of App in the last 30 Days </th>
              </tr>
            </thead>
            <tbody>
              {children.map(c => {
                return (
                  <tr key={c.code}>
                    <td><b>{c.name}</b> <ResponsiblePersons persons={c.responsiblePersons} removeNewLine /></td>
                    {type === 'country' ?
                      <UsageReportStatCell numerator={c.usagereport.constituencyStats.within30Days + c.usagereport.constituencyStats.within7Days} denominator={c.usagereport.constituencyStats.total} structureName="Constituencies" />
                      : 
                      <td style={{ fontWeight: 'bold', color: 'white', backgroundColor: constituencyViewedLabels.find(l => l.field === c.usagereport.lastViewed)?.color }} className='text-center'>{constituencyViewedLabels.find(l => l.field === c.usagereport.lastViewed)?.text}</td>
                    }
                    <UsageReportStatCell numerator={c.usagereport.wardStats.within30Days + c.usagereport.wardStats.within7Days} denominator={c.usagereport.wardStats.total} />
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <i>(*) - since 01 June 2022</i>
        </Row>


        {(assignedWards.length > 0) ? wardCreatedLabels.map(i => {
          return (
            <div className="dont-break" key={i.text} >
              <h2> Wards used App: {i.text}</h2>
              <div style={{ columnWidth: '30em' }}>
                <ul>
                  {assignedWards.filter(w => w.lastCreated === i.field).sort((a, b) => compareStrings(a.name, b.name)).map(w => (
                    <li key={w.code}><b>{w.name}</b> <ResponsiblePersons persons={w.responsiblePersons} removeNewLine /></li>
                  )
                  )}
                </ul>
              </div>
            </div>
          )
        }) : ''
        }

      </>
    )
}
