import React from 'react'

import { Link } from 'react-router-dom'
import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, inProvince, wardNamesCleanup } from '../lib/structures'
import useSummaryData from '../data/useSummaryData'
import { formatInt } from '../lib/utils'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'
import ResponsiblePersons from './ResponsiblePersons'

import Table from 'react-bootstrap/Table'

import { StatCell } from './StatCell'

export default function SummaryView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useSummaryData({ type, code })

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)

  const showMembershipCount = children.some(c => c.type === 'constituency' || c.type === 'ward')

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='summary' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else { 
  return (
    <>
      <PageTitle title={stats.name} />
      <Breadcrumbs structure={stats} />
      <PageHeader structure={stats} />
      <ViewButtons structure={stats} section='summary' />

      <Table responsive striped bordered>
        <thead>
          <tr>
            {stats.type !== 'ward' ? <th /> : null}
            <th className='text-center'>Wards meeting minimum {(inProvince(stats, 'FS') ? 30 : 25)} membership requirement to setup a Branch</th>
            <th className='text-center'>Wards on track to meet their Membership Target</th>
            {showMembershipCount ? <th className='text-center'>Current Members on VRM vs Target for 31&nbsp;Mar&nbsp;2023</th> : null}
            <th className='text-center'>Wards with Valid Branches Constituted</th>
            {inProvince(stats, 'WC') ? null : <th className='text-center'>Wards on track to meet their Activist recruitment Target</th>}
            {inProvince(stats, 'WC') ? null : <th className='text-center'>Wards on track to meet their Canvassing Target</th>}
            <th className='text-center'>Wards on track to meet their DA Voter Registration Target
              {stats.type === 'country'
                ? ' (Roll/Tag)'
                : (inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? ' (Tag)' : ' (Roll)'
              }
            </th>
          </tr>
        </thead>
        <tbody>
          {children.map(c => {
            return (

              <tr key={c.code}>
                {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                <StatCell structureType={c.type} numerator={c.membership.wardStats.minimumAchieved} denominator={c.membership.wardStats.total} trueText='Met' falseText='Not Met' />
                <StatCell structureType={c.type} numerator={c.membership.wardStats.milestoneAchieved} denominator={c.membership.wardStats.total} />
                {showMembershipCount ? <td className='text-center'>{formatInt(c.membership.current)} / {formatInt(c.membership.target)}</td> : null}
                {c.branch.wardStats.agmHeld < 0
                  ? <td className='text-center'>Pending Audit</td>
                  : <StatCell structureType={c.type} numerator={c.branch.wardStats.agmHeld} denominator={c.branch.wardStats.total} trueText='Valid' falseText='Not Valid' />}
                {inProvince(stats, 'WC') ? null : (c.type === 'province' && c.code === 'WC') ? <td>Not being monitored</td> : <StatCell structureType={c.type} numerator={c.activists.wardStats.milestoneAchieved} denominator={c?.activists.wardStats.total} />}
                {inProvince(stats, 'WC') ? null : (c.type === 'province' && c.code === 'WC') ? <td>Not being monitored</td> : <StatCell structureType={c.type} numerator={c.canvassing.wardStats.milestoneAchieved} denominator={c?.canvassing.wardStats.total} />}
                {stats.type === 'country'
                  ? (inProvince(c, 'WC') || inProvince(c, 'NW'))
                    ? <StatCell structureType={c.type} numerator={c.registration.wardStats.milestoneAchievedTag} denominator={c.registration.wardStats.totalTag} structureName='Wards (Tag)' />
                    : <StatCell structureType={c.type} numerator={c.registration.wardStats.milestoneAchieved} denominator={c.registration.wardStats.total} structureName='Wards (Roll)' />
                  : (inProvince(stats, 'WC') || inProvince(stats, 'NW'))
                    ? <StatCell structureType={c.type} numerator={c.registration.wardStats.milestoneAchievedTag} denominator={c.registration.wardStats.totalTag} />
                    : <StatCell structureType={c.type} numerator={c.registration.wardStats.milestoneAchieved} denominator={c.registration.wardStats.total} />
                }
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr className='font-weight-bold'>
            {stats.type !== 'ward' ? (<td>Total</td>) : null}
            <StatCell structureType={stats.type} numerator={stats.membership.wardStats.minimumAchieved} denominator={stats.membership.wardStats.total} trueText='Met' falseText='Not Met' />
            <StatCell structureType={stats.type} numerator={stats.membership.wardStats.milestoneAchieved} denominator={stats.membership.wardStats.total} />
            {showMembershipCount ? <td className='text-center'>{formatInt(stats.membership.current)} / {formatInt(stats.membership.target)}</td> : null}
            {stats.branch.wardStats.agmHeld < 0
              ? <td className='text-center'>Pending Audit</td>
              : <StatCell structureType={stats.type} numerator={stats.branch.wardStats.agmHeld} denominator={stats.branch.wardStats.total} trueText='Valid' falseText='Not Valid' />}
            {inProvince(stats, 'WC') ? null : (stats.type === 'province' && stats.code === 'WC') ? <td>Not being monitored</td> : <StatCell structureType={stats.type} numerator={stats.activists.wardStats.milestoneAchieved} denominator={stats?.activists.wardStats.total} />}
            {inProvince(stats, 'WC') ? null : (stats.type === 'province' && stats.code === 'WC') ? <td>Not being monitored</td> : <StatCell structureType={stats.type} numerator={stats.canvassing.wardStats.milestoneAchieved} denominator={stats?.canvassing.wardStats.total} />}
            {stats.type === 'country'
              ? <StatCell structureType={stats.type} numerator={stats.registration.wardStats.milestoneAchieved} denominator={stats.registration.wardStats.total} structureName='Wards (Roll)' />
              : (inProvince(stats, 'WC') || inProvince(stats, 'NW'))
                ? <StatCell structureType={stats.type} numerator={stats.registration.wardStats.milestoneAchievedTag} denominator={stats.registration.wardStats.totalTag} />
                : <StatCell structureType={stats.type} numerator={stats.registration.wardStats.milestoneAchieved} denominator={stats.registration.wardStats.total} />
            }
          </tr>
        </tfoot>
      </Table>
    </>
  )
  }
}
