import React from 'react'

import { Link } from 'react-router-dom'
import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, inProvince, wardNamesCleanup } from '../lib/structures'
import { useActivists } from '../data/useActivists'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import ResponsiblePersons from './ResponsiblePersons'

import Table from 'react-bootstrap/Table'
import { Col, Row } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'

import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'
import { formatInt, formatPerc } from '../lib/utils'
import { StatCell } from './StatCell'

import {
  Chart as ChartJS,
  registerables
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'

import { Line } from 'react-chartjs-2'

ChartJS.register(annotationPlugin, ...registerables)

export default function ActivistsView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useActivists({ type, code })

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)

  const activistMembershipLine = stats.activists.historic.map(datum => ({ x: datum.date, y: datum.count }))

  const graphData = {
    datasets: [
      {
        label: 'Activist Membership',
        data: activistMembershipLine,
        borderColor: '#70AD47'
      }
    ]
  }

  const graphOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month'
        }
      },
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      autocolors: false,
      annotation: {
        annotations: {
          target: {
            type: 'line',
            yMin: stats.activists.target,
            yMax: stats.activists.target,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              enabled: true,
              content: 'Target'
            }
          }
        }
      }
    }
  }

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/activists' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='activists' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else if (inProvince(stats, 'WC')) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/activists' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='activists' />

        <Alert variant='warning'>Not being monitored</Alert>
      </>
    )
  } else {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/activists' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='activists' />

        <Row>
          <Col md={6}>
            {/* @ts-expect-error */}
            <Line options={graphOptions} data={graphData} />
          </Col>
        </Row>

        <Table responsive striped>
          <thead>
            <tr>
              {stats.type !== 'ward' ? <th /> : null}
              <th className='text-center'>Wards on track to meet their Activist recruitment Target</th>
              <th className='text-center'>Activist Members Change in last 30 days</th>
              <th className='text-center'>Total Trained Activist Members</th>
              <th className='text-center'>% of Members who are Activists</th>
              <th className='text-center'>Wards having held a Training session in last 30 days</th>
              <th className='text-center'>Total Training sessions held since 1 May 2022</th>
              <th className='text-center'>Total Untrained Active Members </th>
            </tr>
          </thead>
          <tbody>
            {children.map(c => {
              return (c.type === 'province' && c.code === 'WC')
                ? (
                  <tr key={c.code}>
                    <td>{c.name}<ResponsiblePersons persons={c.responsiblePersons} /></td>
                    <td className='text-center' colSpan={7}>Not being monitored</td>
                  </tr>
                  )
                : (
                  <tr key={c.code}>
                    {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}/activists`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                    <StatCell structureType={c.type} numerator={c.activists.wardStats.milestoneAchieved} denominator={c.activists.wardStats.total} />
                    <td className='text-center'>{formatInt(c.activists.current - c.activists.lastmonth)}</td>
                    <td className='text-center'>{formatInt(c.activists.current)}</td>
                    <td className='text-center'>{formatPerc(c.activists.current / c.activists.membership)}</td>
                    <StatCell structureType={c.type} numerator={c.activists.wardStats.trainingHeldAchieved} denominator={c.activists.wardStats.total} />
                    <td className='text-center'>{formatInt(c.activists.trainingHeld)}</td>
                    <td className='text-center'>{formatInt(c.activists.untrainedActive - c.activists.current)}</td>
                  </tr>
                  )
            })}
          </tbody>
          <tfoot>
            <tr className='font-weight-bold'>
              {stats.type !== 'ward' ? <td>Total</td> : null}
              <StatCell structureType={stats.type} numerator={stats.activists.wardStats.milestoneAchieved} denominator={stats.activists.wardStats.total} />
              <td className='text-center'>{formatInt(stats.activists.current - stats.activists.lastmonth)}</td>
              <td className='text-center'>{formatInt(stats.activists.current)}</td>
              <td className='text-center'>{formatPerc(stats.activists.current / stats.activists.membership)}</td>
              <StatCell structureType={stats.type} numerator={stats.activists.wardStats.trainingHeldAchieved} denominator={stats.activists.wardStats.total} />
              <td className='text-center'>{formatInt(stats.activists.trainingHeld)}</td>
              <td className='text-center'>{formatInt(stats.activists.untrainedActive - stats.activists.current)}</td>

            </tr>
          </tfoot>
        </Table>
      </>
    )
  }
}
