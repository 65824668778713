import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, wardNamesCleanup, inProvince } from '../lib/structures'
import useBranchData from '../data/useBranchData'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import ResponsiblePersons from './ResponsiblePersons'

import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'
import Map from './Map'

import Table from 'react-bootstrap/Table'

import { statsStyle } from '../lib/utils'
import { StatCell } from './StatCell'

export default function BranchView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useBranchData({ type, code })

  const [mapChoice, setMapChoice] = useState<string>('minimumAchieved')

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)

  const mapColor = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return '#ffffff'
    // @ts-expect-error
    const color = statsStyle(c.branch.wardStats[mapChoice], c.branch.wardStats.total).backgroundColor
    return color
  }

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/branch' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='branch' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else {
  return (
    <>
      <PageTitle title={stats.name} />
      <Breadcrumbs structure={stats} suffix='/branch' />
      <PageHeader structure={stats} />
      <ViewButtons structure={stats} section='branch' />

      <div className='my-2' style={{ textAlign: 'center' }}>
        <Map type={type} code={code} childStructures={stats.children} childColourFn={mapColor} />
        <b>Show on map:&nbsp;</b>
        <select value={mapChoice} onChange={event => setMapChoice(event.target.value)}>
          <option value='minimumAchieved'>Minimum Membership</option>
          <option value='audited'>Audited</option>
          <option value='agmHeld'>AGM Held</option>
        </select>
      </div>

      <Table responsive striped bordered>
        <thead>
          <tr>
            {stats.type !== 'ward' ? <th /> : null}
            <th className='text-center'>Wards meeting minimum {(inProvince(stats, 'FS') ? 30 : 25)} membership requirement to setup a Branch</th>
            <th className='text-center'>Wards with Audited minimum Membership requirement for valid Branch </th>
            <th className='text-center'>Wards with valid branch having held a valid AGM</th>
          </tr>
        </thead>
        <tbody>
          {children.map(c => {
            return (
              <tr key={c.code}>
                {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}/branch`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                <StatCell structureType={c.type} numerator={c.branch.wardStats.minimumAchieved} denominator={c.branch.wardStats.total} trueText='Met' falseText='Not Met' />
                {c.branch.wardStats.audited < 0
                  ? <td className='text-center'>Pending Audit</td>
                  : <StatCell structureType={c.type} numerator={c.branch.wardStats.audited} denominator={c.branch.wardStats.total} trueText='Met' falseText='Not Met' />}
                {c.branch.wardStats.agmHeld < 0
                  ? <td className='text-center'>Pending Audit</td>
                  : <StatCell structureType={c.type} numerator={c.branch.wardStats.agmHeld} denominator={c.branch.wardStats.total} trueText='Met' falseText='Not Met' />}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr className='font-weight-bold'>
            {stats.type !== 'ward' ? (<td>Total</td>) : null}
            <StatCell structureType={stats.type} numerator={stats.branch.wardStats.minimumAchieved} denominator={stats.branch.wardStats.total} trueText='Met' falseText='Not Met' />
            {stats.branch.wardStats.audited < 0
              ? <td className='text-center'>Pending Audit</td>
              : <StatCell structureType={stats.type} numerator={stats.branch.wardStats.audited} denominator={stats.branch.wardStats.total} trueText='Met' falseText='Not Met' />}
            {stats.branch.wardStats.agmHeld < 0
              ? <td className='text-center'>Pending Audit</td>
              : <StatCell structureType={stats.type} numerator={stats.branch.wardStats.agmHeld} denominator={stats.branch.wardStats.total} trueText='Met' falseText='Not Met' />}
          </tr>
        </tfoot>
      </Table>
    </>
  )
  }
}
