import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'
import type { TimelineDatum, Timeline } from '../types/Timeline'

interface MembershipDataForChildren {
  current: number
  lastWeek: number
  lastMonth: number
  milestone: number
  target: number
  wardStats: {
    total: number
    milestoneAchieved: number
    minimumAchieved: number
  }
  expiring: Timeline<number>
}

interface MembershipDataForSelf extends MembershipDataForChildren {
  historic: Timeline<number>
  milestones: Timeline<number>
}

export interface StructureWithMembership extends StructureWithMembershipBase {
  membership: MembershipDataForSelf
  parents: StructureBase[]
  children: StructureWithMembershipBase[]
}

interface StructureWithMembershipBase extends StructureBase {
  membership: MembershipDataForChildren
}

interface MembershipResult {
  structure: StructureWithMembership
}

const query = gql`
query getMembershipData($type: String!, $code: String!, $childType: String!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    membership {
      wardStats {
        total
        milestoneAchieved
        minimumAchieved
      }
      current: count(days: 0)
      lastWeek: count(days: 7)
      lastMonth: count(days: 30)
      milestone
      target
      historic(startDate: "2022-05-01") {
        date
        count
      }
      expiring {
        date
        count
      }
      milestones {
        date
        count
      }
    }
    parents { type code name userHasAccess }
    children(type: $childType) {
      type
      code
      name
      responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
      membership {
        wardStats {
          total
          milestoneAchieved
          minimumAchieved
        }
        current: count(days: 0)
        lastWeek: count(days: 7)
        lastMonth: count(days: 30)
        milestone
        target
        expiring {
          date
          count
        }
      }
    }
  }
}
`

export function useMembership ({ type, code }: StructParams): StructureWithMembership | undefined {
  const childType = getChildType({ type, code })
  const { error, data } = useQuery<MembershipResult, QueryStructParams>(
    query, { variables: { type, code, childType } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}

export function calcExpiringMembership (currentDatum: TimelineDatum<number>, expiring: Timeline<number>): Timeline<number> {
  const { date, count } = currentDatum
  let current = count

  const currentExpiring: Timeline<number> = []
  currentExpiring.push({ date, count })

  for (const datum of expiring) {
    current -= datum.count
    currentExpiring.push(
      {
        date: datum.date,
        count: current
      }
    )
  }
  return currentExpiring
}
