import React from 'react'

import { Link } from 'react-router-dom'
import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, wardNamesCleanup } from '../lib/structures'
import usePublicityData from '../data/usePublicityData'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import ResponsiblePersons from './ResponsiblePersons'

import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'

import Table from 'react-bootstrap/Table'

// import { statsStyle } from '../lib/utils'
import { PublicityActivityCell, PublicityStatCell } from './StatCell'

export default function PublicityView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = usePublicityData({ type, code })

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/publicity' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='publicity' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else {
    return (
    <>
      <PageTitle title={stats.name} />
      <Breadcrumbs structure={stats} suffix='/publicity' />
      <PageHeader structure={stats} />
      <ViewButtons structure={stats} section='publicity' />

      <Table responsive striped bordered>
        <thead>
          <tr>
            {stats.type !== 'ward' ? <th /> : null}
            {stats.publicity.columns.map(col => {
              return (
                <th key={col.field} className='text-center'>{col.displayName}<br />({col.targetDesc})</th>
              )
            })}
          </tr>
        </thead>
          <tbody>
          {children.map(c => {
            return (
              <tr key={c.code}>
                {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}/publicity`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                {stats.publicity.columns.map(col => {
                  if (c.type === 'ward') {
                    const publicityActivityCountsField = c.publicity.activityCounts.find(f => f.field === col.field)
                    return <PublicityActivityCell publicityActivityCountsField={publicityActivityCountsField} />
                  } else {
                    const publicityWardStatsField = c.publicity.wardStats.find(f => f.field === col.field)
                    return <PublicityStatCell publicityWardStatsField={publicityWardStatsField} />
                  }
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
            <tr className='font-weight-bold'>
            {stats.type !== 'ward' ? <td>Total</td> : null}
            {stats.publicity.columns.map(col => {

              if (stats.type === 'ward') {
                const publicityActivityCountsField = stats.publicity.activityCounts.find(f => f.field === col.field)
                return <PublicityActivityCell publicityActivityCountsField={publicityActivityCountsField} />
              } else {
                const publicityWardStatsField = stats.publicity.wardStats.find(f => f.field === col.field)
                return <PublicityStatCell publicityWardStatsField={publicityWardStatsField} />
              }
            })}
          </tr>
        </tfoot>
      </Table>
    </>
    )
  }
}
