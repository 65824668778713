import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'
import type { Timeline } from '../types/Timeline'

interface CanvassingDataForChildren {
  current: number
  lastweek: number
  lastmonth: number
  milestone: number
  target: number
  wardStats: {
    total: number
    milestoneAchieved: number
  }
}

interface CanvassingDataForSelf extends CanvassingDataForChildren {
  historic: Timeline<number>
  milestones: Timeline<number>
}

export interface StructureWithCanvassing extends StructureWithCanvassingBase {
  canvassing: CanvassingDataForSelf
  parents: StructureBase[]
  children: StructureWithCanvassingBase[]
}

interface StructureWithCanvassingBase extends StructureBase {
  canvassing: CanvassingDataForChildren
}

interface CanvassingResult {
  structure: StructureWithCanvassing
}

const query = gql`
query getCanvassingData($type: String!, $code: String!, $childType: String!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    canvassing {
        wardStats {
          total
          milestoneAchieved
        }
        current: count(days: 0)
        lastweek: count(days: 7)
        lastmonth: count(days: 30)
        milestone
        target
        historic {
          date
          count
        }
        milestones{
          date
          count
        }
      }
    parents { type code name userHasAccess }
    children(type: $childType) {
      type
      code
      name
      responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
      canvassing {
        wardStats {
          total
          milestoneAchieved
        }
        current: count(days: 0)
        lastweek: count(days: 7)
        lastmonth: count(days: 30)
        milestone
        target
      }
    }
  }
}
`

export function useCanvassing ({ type, code }: StructParams): StructureWithCanvassing | undefined {
  const childType = getChildType({ type, code })
  const { error, data } = useQuery<CanvassingResult, QueryStructParams>(
    query, { variables: { type, code, childType } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}
