import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'

export interface StructureSummary extends StructureWithSummaryData {
  parents: StructureBase[]
  children: StructureWithSummaryData[]
}

interface StructureWithSummaryData extends StructureBase {
  membership: {

    wardStats: {
      total: number
      milestoneAchieved: number
      minimumAchieved: number
    }
    current: number
    target: number
  }

  branch: {
    wardStats: {
      total: number
      agmHeld: number
    }
  }

  activists: {
    wardStats: {
      total: number
      milestoneAchieved: number
    }
  }

  canvassing: {
    wardStats: {
      total: number
      milestoneAchieved: number
    }
  }

  registration: {
    wardStats: {
      total: number
      totalTag: number
      milestoneAchieved: number
      milestoneAchievedTag: number
    }
  }
}

interface SummaryResult {
  structure: StructureSummary
}

const query = gql`
query getSummaryData($type: String!, $code: String!, $childType: String!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    membership {
      wardStats {
        total
        milestoneAchieved
        minimumAchieved
      }
      current: count(days: 0)
      target
    }
    branch {
      wardStats {
        total
        agmHeld 
      }
    }
    activists {
      wardStats {
        total
        milestoneAchieved
      }
    }
    canvassing {
      wardStats {
        total
        milestoneAchieved
      }
    }
    registration {
      wardStats {
        total
        totalTag
        milestoneAchieved
        milestoneAchievedTag
      }
    }
    parents { type code name userHasAccess }
    children(type: $childType) {
      type
      code
      name
    responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
      membership {
      wardStats {
        total
        milestoneAchieved
        minimumAchieved
      }
      current: count(days: 0)
      target
    }
    branch {
      wardStats {
        total
        agmHeld 
      }
    }
    activists {
      wardStats {
        total
        milestoneAchieved
      }
    }
    canvassing {
      wardStats {
        total
        milestoneAchieved
      }
    }
    registration {
      wardStats {
        total
        totalTag
        milestoneAchieved
        milestoneAchievedTag
      }
    }
    }
  }
}
`

export default function useSummaryData ({ type, code }: StructParams): StructureSummary | undefined {
  const childType = getChildType({ type, code })
  const { error, data } = useQuery<SummaryResult, QueryStructParams>(
    query, { variables: { type, code, childType } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}
