import type { ReactChild, ReactPortal } from 'react'

export const compareStrings = (a: string, b: string): number => a < b ? -1 : (a > b ? 1 : 0)

export const formatInt = (num: number | string | null | undefined): string => {
  if (num == null) {
    return ''
  } else if (typeof num === 'number') {
    return num.toLocaleString('en-GB', { maximumFractionDigits: 0 })
  } else {
    return parseInt(num).toLocaleString('en-GB', { maximumFractionDigits: 0 })
  }
}

export const formatPerc = (num: number | string | null | undefined): string => {
  if (num == null) {
    return ''
  } else if (typeof num === 'number') {
    return (num * 100).toLocaleString('en-GB', { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%'
  } else {
    return (parseFloat(num) * 100).toLocaleString('en-GB', { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + '%'
  }
}

export const statsStyle = (achieved: number, total: number): {fontWeight: string, color: string, backgroundColor: string} => {
  let backgroundColor = 'black'

  const perc = achieved / total * 100
  if (perc >= 100) {
    backgroundColor = '#0070c0' // Blue
  } else if (perc >= 75) {
    backgroundColor = '#70ad47' // Green
  } else if (perc >= 45) {
    backgroundColor = '#ff7f00' // Orange
  } else if (perc >= 20) {
    backgroundColor = '#ff0000' // Red
  } else {
    backgroundColor = '#c00000' // Dark Red
  }

  return { fontWeight: 'bold', color: 'white', backgroundColor }
}

export const firstCap = (str: string): string =>
  str.charAt(0).toUpperCase() + str.substring(1)

// see https://fettblog.eu/react-types-for-children-are-broken/
type ReactNode =
  | ReactChild
  | ReactNode[]
  | ReactPortal
  | boolean
  | null
  | undefined

export type WithChildren<T = {}> = T & { children?: ReactNode }
