import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'

import { LoadingSpinner } from './spinners'
import IndexView from './IndexView'
import SummaryView from './SummaryView'
import MembershipView from './MembershipView'
import BranchView from './BranchView'
import ActivistsView from './ActivistsView'
import PublicityView from './PublicityView'
import CanvassingView from './CanvassingView'
import RegistrationView from './RegistrationView'
import UsageReportView from './UsageReportView'
import AdminView from './AdminView'


const AppRoutes = (): JSX.Element => (
  <Suspense fallback={<LoadingSpinner />}>
    <Routes>
      <Route path='/' element={<IndexView />} />
      <Route path='/:type/:code' element={<SummaryView />} />
      <Route path='/:type/:code/membership' element={<MembershipView />} />
      <Route path='/:type/:code/branch' element={<BranchView />} />
      <Route path='/:type/:code/activists' element={<ActivistsView />} />
      <Route path='/:type/:code/publicity' element={<PublicityView />} />
      <Route path='/:type/:code/canvassing' element={<CanvassingView />} />
      <Route path='/:type/:code/registration' element={<RegistrationView />} />
      <Route path='/admin' element={<AdminView />} />
      <Route path='/admin/:type/:code/usagereport' element={<UsageReportView />} />
    </Routes>
  </Suspense>
)

export default AppRoutes
