import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'
import type { Timeline } from '../types/Timeline'

export interface UsageReportConstituencyStats {
  total: number
  within7Days: number
  within30Days: number
  over30Days: number
  neverViewed: number
  noResponsiblePerson: number
}

export interface UsageReportWardStats {
  total: number
  within7Days: number
  within30Days: number
  over30Days: number
  neverCreated: number
}

export type UsageReportLastViewedType = 'within7Days' | 'within30Days' | 'over30Days' | 'neverViewed' | 'noResponsiblePerson'
export type UsageReportLastCreatedType = 'within7Days' | 'within30Days' | 'over30Days' | 'neverCreated'

interface UsageReportDataForChildren {
  constituencyStats: UsageReportConstituencyStats
  wardStats: UsageReportWardStats
  lastViewed: UsageReportLastViewedType
  lastCreated: UsageReportLastCreatedType
}

interface UsageReportDataForSelf extends UsageReportDataForChildren {
  constituencyViewedDaily: Timeline<number>
  wardCreatedDaily: Timeline<number>
  wards: StructureWithUsageReportWard[]
}

interface StructureWithUsageReportWard extends StructureBase {
  lastCreated: UsageReportLastCreatedType
}

interface StructureWithUsageReport extends StructureWithUsageReportBase {
  usagereport: UsageReportDataForSelf
  parents: StructureBase[]
  children: StructureWithUsageReportBase[]
}

interface StructureWithUsageReportBase extends StructureBase {
  usagereport: UsageReportDataForChildren
}

interface UsageReportResult {
  structure: StructureWithUsageReport
}

const query = gql`
query getUsageReportData($type: String!, $code: String!, $childType: String!, $includeWards: Boolean!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    usagereport {
      constituencyStats {
        total
        within7Days
        within30Days
        over30Days
        neverViewed
        noResponsiblePerson
      }
      wardStats {
        total
        within7Days
        within30Days
        over30Days
        neverCreated
      }
      constituencyViewedDaily {
        date
        count
      }
      wardCreatedDaily {
        date
        count
      }
      wards @include(if: $includeWards) {
          type
          code
          name
          responsiblePersons { firstname surname displayname }
          lastCreated
        }
    }
    parents { type code name userHasAccess userHasAdminAccess }
    children(type: $childType) {
      type
      code
      name
      responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
      usagereport {
        constituencyStats {
          total
          within7Days
          within30Days
          over30Days
          neverViewed
          noResponsiblePerson
        }
        wardStats {
          total
          within7Days
          within30Days
        }
        lastViewed
      }
    }
  }
}
`

export function useUsageReport ({ type, code }: StructParams): StructureWithUsageReport | undefined {
  const childType = (type === 'province') ? 'constituency' : getChildType({ type, code })
  const includeWards = (type !== 'country')
  const { error, data } = useQuery<UsageReportResult, QueryStructParams>(
    query, { variables: { type, code, childType, includeWards } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}
