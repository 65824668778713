import React from 'react'
import Container from 'react-bootstrap/Container'
import MainNav from './MainNav'
import Alert from 'react-bootstrap/Alert'
import type { WithChildren } from '../lib/utils'

const Layout = ({ children }: WithChildren): JSX.Element => (
  <>
    <MainNav />
    <Container fluid className='my-3'>
      <Alert key='one' variant='warning'>
        <b>
          The MDMB targets came to an end on 31 March 2023.
          We are awaiting guidance from FedEx Oversight Committee on an extension of the programme.
          For now, the dashboard, and the stats, and reporting will be suspended.
        </b>
      </Alert>

      {children}
    </Container>
  </>
)

export default Layout
