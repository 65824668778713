import React from 'react'

import { Link } from 'react-router-dom'
import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, wardNamesCleanup, inProvince } from '../lib/structures'
import { useRegistration } from '../data/useRegistration'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import ResponsiblePersons from './ResponsiblePersons'

import Table from 'react-bootstrap/Table'
import { Col, Row } from 'react-bootstrap'

import Map from './Map'

import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'
import { statsStyle, formatInt, formatPerc } from '../lib/utils'
import { StatCell } from './StatCell'

import {
  Chart as ChartJS,
  registerables
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'

import { Line } from 'react-chartjs-2'

ChartJS.register(annotationPlugin, ...registerables)

export default function RegistrationView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useRegistration({ type, code })

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)

  const mapColor = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return '#ffffff'
    const color = statsStyle(c.registration.wardStats.milestoneAchieved, c.registration.wardStats.total).backgroundColor
    return color
  }

  const mapColorTag = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return '#ffffff'
    const color = statsStyle(c.registration.wardStats.milestoneAchievedTag, c.registration.wardStats.totalTag).backgroundColor
    return color
  }

  const historicRollLine = stats.registration.historicRoll.map(datum => ({ x: datum.date, y: datum.count }))
  const historicTagLine = stats.registration.historicTag.map(datum => ({ x: datum.date, y: datum.count }))
  const milestonesLine = stats.registration.milestones.map(datum => ({ x: datum.date, y: datum.count }))

  const graphData = {
    datasets: (inProvince(stats, 'WC') || inProvince(stats, 'NW'))
      ? [
          {
            label: 'Registration Roll',
            data: historicRollLine,
            borderColor: '#4D77FF'
          },
          {
            label: 'Registration Tag',
            data: historicTagLine,
            borderColor: '#70AD47'
          },
          {
            label: 'Registration Worm',
            data: milestonesLine,
            borderColor: '#56BBF1',
            borderDash: [2, 2],
            pointRadius: 0
          }
        ]
      : [
          {
            label: 'Registration',
            data: historicRollLine,
            borderColor: '#4D77FF'
          },
          {
            label: 'Registration Worm',
            data: milestonesLine,
            borderColor: '#56BBF1',
            borderDash: [2, 2],
            pointRadius: 0
          }
        ]
  }

  const graphOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month'
        }
      },
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      autocolors: false,
      annotation: {
        annotations: {
          target: {
            type: 'line',
            yMin: stats.registration.target,
            yMax: stats.registration.target,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              enabled: true,
              content: 'Target'
            }
          }
        }
      }
    }
  }

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/registration' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='registration' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/registration' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='registration' />

        <Row>
          <Col md={6}>
            <div style={{ position: 'relative', height: '400px', width: '100%' }}>
              {/* @ts-expect-error */}
              <Line options={graphOptions} data={graphData} />
            </div>
          </Col>
          <Col md={6}>
            <Map type={type} code={code} childStructures={stats.children} childColourFn={inProvince(stats, 'WC') || inProvince(stats, 'NW') ? mapColorTag : mapColor} />
          </Col>
        </Row>

        <Table responsive striped className='break-before'>
          <thead>
            <tr>
              {stats.type !== 'ward' ? <th /> : null}
              {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <th className='text-center'>Wards on track to meet their DA Voter Registration Target, per VRM tag</th> : null}
              {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <th className='text-center'>DA Voters assisted to complete IEC Registration, per VRM tag</th> : null}
              <th className='text-center'>Wards on track to meet their DA Voter Registration Target, per the IEC since LGE 2021</th>
              <th className='text-center'>DA Voters confirmed registered, per the IEC since LGE 2021</th>
              {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <th className='text-center'>Difference between VRM tag and Voters Roll</th> : null}
              <th className='text-center'>DA Registrations we should have done by now </th>
              <th className='text-center'>Actual DA Registrations as a % of where we should be</th>
              <th className='text-center'>DA Voter Registration Target by 31 Mar 2023</th>
            </tr>
          </thead>
          <tbody>
            {children.map(c => (
              <tr key={c.code}>
                {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}/registration`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <StatCell structureType={c.type} numerator={c.registration.wardStats.milestoneAchievedTag} denominator={c.registration.wardStats.totalTag} /> : null}
                {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <td className='text-center'>{formatInt(c.registration.currentTag)}</td> : null}
                <StatCell structureType={c.type} numerator={c.registration.wardStats.milestoneAchieved} denominator={c.registration.wardStats.total} />
                <td className='text-center'>{formatInt(c.registration.current)}</td>
                {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <td className='text-center'>{formatInt(c.registration.current - c.registration.currentTag)}</td> : null}
                <td className='text-center'>{formatInt(c.registration.milestone)}</td>
                <td className='text-center'>{(c.registration.milestone === 0) ? '-' : formatPerc(c.registration.current / c.registration.milestone)}</td>
                <td className='text-center'>{formatInt(c.registration.target)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className='font-weight-bold'>
              {stats.type !== 'ward' ? <td>Total</td> : null}
              {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <StatCell structureType={stats.type} numerator={stats.registration.wardStats.milestoneAchievedTag} denominator={stats.registration.wardStats.totalTag} /> : null}
              {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <td className='text-center'>{formatInt(stats.registration.currentTag)}</td> : null}
              <StatCell structureType={stats.type} numerator={stats.registration.wardStats.milestoneAchieved} denominator={stats.registration.wardStats.total} />
              <td className='text-center'>{formatInt(stats.registration.current)}</td>
              {(inProvince(stats, 'WC') || inProvince(stats, 'NW')) ? <td className='text-center'>{formatInt(stats.registration.current - stats.registration.currentTag)}</td> : null}
              <td className='text-center'>{formatInt(stats.registration.milestone)}</td>
              <td className='text-center'>{(stats.registration.milestone === 0) ? '-' : formatPerc(stats.registration.current / stats.registration.milestone)}</td>
              <td className='text-center'>{formatInt(stats.registration.target)}</td>
            </tr>
          </tfoot>
        </Table>
      </>
    )
  }
}
