import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { LinkContainer } from 'react-router-bootstrap'
import { StructureBase, inProvince } from '../lib/structures'
import { useLocation } from 'react-router-dom'
import { getLocalToken } from 'react-u5auth'

interface ViewButtonsProps {
  structure: StructureBase
  section: string
  admin?: boolean
}

const userSections = [
  { id: 'summary', suffix: '', title: 'Summary' },
  { id: 'membership', suffix: '/membership', title: 'Membership' },
  { id: 'branch', suffix: '/branch', title: 'Branch' },
  { id: 'activists', suffix: '/activists', title: 'Activists' },
  { id: 'publicity', suffix: '/publicity', title: 'Publicity' },
  { id: 'canvassing', suffix: '/canvassing', title: 'Canvassing' },
  { id: 'registration', suffix: '/registration', title: 'Registration' }
]

const adminSections = [
  { id: 'usagereport', suffix: '/usagereport', title: 'Usage Report' }
]

export default function ViewButtons ({ structure, section, admin = false }: ViewButtonsProps): JSX.Element {

  const renderSections = admin ? adminSections : userSections.filter(s => !(inProvince(structure, 'WC') && ['activists', 'canvassing'].includes(s.id)))


  const pdfRoot = (process.env.REACT_APP_API_URL ?? '').replace(/graphql$/, 'pdf')
  const location = useLocation()
  const token = getLocalToken() ?? ''
  const pdfUrl = `${pdfRoot}${location.pathname}?token=${token}`

  return (
    <>
      <div className='my-3 d-print-none'>
        <div style={{ float: 'right' }}>
          <a className='btn btn-primary' href={pdfUrl}>Download PDF</a>
        </div>
        <ButtonGroup>
          {renderSections.map(s => s.id === section
            ? <Button key={s.id} variant='primary'>{s.title}</Button>
            : <LinkContainer key={s.id} to={`/${structure.type}/${structure.code}${s.suffix}`}><Button variant='secondary'>{s.title}</Button></LinkContainer>
          )}
        </ButtonGroup>
      </div>
      <div className='my-3 d-none d-print-block'>
        <h4>{renderSections.find(s => s.id === section)?.title}</h4>
      </div>
    </>
  )
}
