import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { AuthProvider, Authenticated } from './infra/auth'
import { ApolloProvider } from './infra/apollo'
import Appsignal from '@appsignal/javascript'
import { plugin as appSignalBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network'

import { ErrorDisplay } from './components/ErrorBoundary'

import { ErrorBoundary } from '@appsignal/react'
import Routes from './components/Routes'

import './App.css'
import Layout from './components/Layout'

const appsignal = new Appsignal({
  key: process.env.REACT_APP_APPSIGNAL_PUSH_API_KEY,
  revision: process.env.REACT_APP_GIT_SHORT_SHA
})

appsignal.use(appSignalBreadcrumbsNetwork())

const App = (): JSX.Element => (
  <HelmetProvider>
    <AuthProvider>
      <ApolloProvider>
        <BrowserRouter>
          <Layout>
            <ErrorBoundary
              instance={appsignal}
              fallback={(error: any) => <ErrorDisplay error={error} />}
            >
              <Authenticated>
                <Routes />
              </Authenticated>
            </ErrorBoundary>
          </Layout>
        </BrowserRouter>
      </ApolloProvider>
    </AuthProvider>
  </HelmetProvider>
)

export default App
