import React from 'react'

import { Link } from 'react-router-dom'
import useStructureParams from '../lib/useStructureParams'
import { filterAndSortChildren, wardNamesCleanup } from '../lib/structures'
import { useMembership, calcExpiringMembership } from '../data/useMembership'

import PageTitle from './PageTitle'
import Breadcrumbs from './Breadcrumbs'
import PageHeader from './PageHeader'
import ViewButtons from './ViewButtons'
import ResponsiblePersons from './ResponsiblePersons'

import Table from 'react-bootstrap/Table'
import { Col, Row } from 'react-bootstrap'

import Map from './Map'

import { LoadingSpinner } from './spinners'
import { NoAssignedWardsMessage } from './alerts'
import { statsStyle, formatInt } from '../lib/utils'
import { StatCell } from './StatCell'

import {
  Chart as ChartJS,
  ChartDataset,
  registerables
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import annotationPlugin from 'chartjs-plugin-annotation'

import { Line } from 'react-chartjs-2'

import { subDays, format } from 'date-fns'

ChartJS.register(annotationPlugin, ...registerables)

export default function MembershipView (): JSX.Element {
  // get parameters from the URL
  const { code, type } = useStructureParams()

  // get stats from the GraphQL API
  const stats = useMembership({ type, code })

  // this happens while the query is still fetching
  if (stats == null) {
    return <LoadingSpinner />
  }

  // filter/sort children appropriately according to structure type
  const children = filterAndSortChildren(stats.type, stats.children)
    .map(wardNamesCleanup)
    .filter(c => c.structureHasAssignedWards)

  const mapColor = (code: string): string => {
    const c = children.find(c => c.code === code)
    if (c == null) return '#ffffff'
    const color = statsStyle(c.membership.wardStats.milestoneAchieved, c.membership.wardStats.total).backgroundColor
    return color
  }

  const historicMembershipLine = stats.membership.historic.map(datum => ({ x: datum.date, y: datum.count }))
  const expiringMembershipLine = calcExpiringMembership({ date: format(subDays(new Date(), 1), 'yyyy-MM-dd'), count: stats.membership.current }, stats.membership.expiring).map(datum => ({ x: datum.date, y: datum.count }))
  const membershipMilestonesLine = stats.membership.milestones.map(datum => ({ x: datum.date, y: datum.count }))

  const graphDatasets: Array<ChartDataset<'line', Array<{ x: string, y: number }>>> = [
    {
      label: 'Membership',
      data: historicMembershipLine,
      borderColor: '#4D77FF'
    },
    {
      label: 'Expiring Membership',
      data: expiringMembershipLine,
      borderColor: '#56BBF1',
      borderDash: [5, 5],
      pointRadius: 0,
      stepped: true
    }
  ]

  if (stats.membership.target > stats.membership.current) {
    graphDatasets.push(
      {
        label: 'Membership Worm',
        data: membershipMilestonesLine,
        borderColor: '#56BBF1',
        borderDash: [2, 2],
        pointRadius: 0
      }
    )
  }

  const graphData = {
    datasets: graphDatasets
  }

  const graphOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month'
        }
      },
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      autocolors: false,
      annotation: {
        annotations: {
          target: {
            type: 'line',
            yMin: stats.membership.target,
            yMax: stats.membership.target,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              enabled: true,
              content: 'Target'
            }
          }
        }
      }
    }
  }

  if (!stats.structureHasAssignedWards) {
    return (
      <>
        <PageTitle title={stats.name} />
        <Breadcrumbs structure={stats} suffix='/membership' />
        <PageHeader structure={stats} />
        <ViewButtons structure={stats} section='membership' />

        <NoAssignedWardsMessage type={stats.type} />
      </>
    )
  } else {
  return (
    <>
      <PageTitle title={stats.name} />
      <Breadcrumbs structure={stats} suffix='/membership' />
      <PageHeader structure={stats} />
      <ViewButtons structure={stats} section='membership' />

      <Row>
        <Col md={6}>
          <div style={{ position: 'relative', height: '400px', width: '100%' }}>
            {/* @ts-expect-error */}
            <Line options={graphOptions} data={graphData} />
          </div>
        </Col>
        <Col md={6}>
          <Map type={type} code={code} childStructures={stats.children} childColourFn={mapColor} />
        </Col>
      </Row>

      <Table responsive striped className='break-before'>
        <thead>
          <tr>
            {stats.type !== 'ward' ? <th /> : null}
            <th className='text-center'>Wards on track to meet their Membership Target</th>
            <th className='text-center'>Nett change in Membership in last 7 days </th>
            <th className='text-center'>Nett change in Membership in last 30 days </th>
            <th className='text-center'>Current Members on VRM</th>
            <th className='text-center'>Number of Members we should have by now</th>
            <th className='text-center'>Target Members by 31&nbsp;Mar&nbsp;2023</th>
            <th className='text-center'>Expiring Current Month</th>
            <th className='text-center'>Expiring Next Month</th>
            <th className='text-center'>Expiring Two Months</th>
          </tr>
        </thead>
        <tbody>
          {children.map(c => {
            return (
              <tr key={c.code}>
                {stats.type !== 'ward' ? <td><Link to={`/${c.type}/${c.code}/membership`}>{c.name}</Link> <ResponsiblePersons persons={c.responsiblePersons} /></td> : null}
                <StatCell structureType={c.type} numerator={c.membership.wardStats.milestoneAchieved} denominator={c.membership.wardStats.total} />
                <td className='text-center'>{formatInt(c.membership.current - c.membership.lastWeek)}</td>
                <td className='text-center'>{formatInt(c.membership.current - c.membership.lastMonth)}</td>
                <td className='text-center'>{formatInt(c.membership.current)}</td>
                <td className='text-center'>{(c.membership.target > c.membership.current) ? formatInt(c.membership.milestone) : 'Above Target'}</td>
                <td className='text-center'>{formatInt(c.membership.target)}</td>
                <td className='text-center'>{formatInt(c.membership.expiring[0]?.count)}</td>
                <td className='text-center'>{formatInt(c.membership.expiring[1]?.count)}</td>
                <td className='text-center'>{formatInt(c.membership.expiring[2]?.count)}</td>
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr className='font-weight-bold'>
            {stats.type !== 'ward' ? <td>Total</td> : null}
            <StatCell structureType={stats.type} numerator={stats.membership.wardStats.milestoneAchieved} denominator={stats.membership.wardStats.total} />
            <td className='text-center'>{formatInt(stats.membership.current - stats.membership.lastWeek)}</td>
            <td className='text-center'>{formatInt(stats.membership.current - stats.membership.lastMonth)}</td>
            <td className='text-center'>{formatInt(stats.membership.current)}</td>
            <td className='text-center'>{(stats.membership.target > stats.membership.current) ? formatInt(stats.membership.milestone) : 'Above Target'}</td>
            <td className='text-center'>{formatInt(stats.membership.target)}</td>
            <td className='text-center'>{formatInt(stats.membership.expiring[0]?.count)}</td>
            <td className='text-center'>{formatInt(stats.membership.expiring[1]?.count)}</td>
            <td className='text-center'>{formatInt(stats.membership.expiring[2]?.count)}</td>
          </tr>
        </tfoot>
      </Table>
    </>
  )
}
}
