import React from 'react'
import { Helmet } from 'react-helmet-async'
import ListGroup from 'react-bootstrap/ListGroup'
import { LinkContainer } from 'react-router-bootstrap'

import { LoadingSpinner } from './spinners'
import { ForbiddenMessage } from './alerts'
import { compareStrings, firstCap } from '../lib/utils'
import { structConf } from '../lib/structures'
import useAdminStructures from '../data/useAdminStructures'

const sortKey: Record<string, number> = {
  province: 1,
  region: 2,
  constituency: 3,
  municipality: 4,
  ward: 5,
  votingdistrict: 6
}

export default function AdminView (): JSX.Element {
  const adminStructures = useAdminStructures()

  if (adminStructures == null) {
    return <LoadingSpinner />
  }

  if (adminStructures.length === 0) {
    return <ForbiddenMessage forbiddenItem="this page" />
  }

  // copy because Apollo may hand us an Immutable
  const sortedStructures = [...adminStructures]

  sortedStructures.sort((a, b) => {
    if (a.type === b.type) {
      return compareStrings(a.name, b.name)
    } else {
      return sortKey[a.type] - sortKey[b.type]
    }
  })

  return (
    <>
      <Helmet>
        <title>My DA My Branch Dashboard</title>
      </Helmet>

      <h2>Managament Reports</h2>

      <ListGroup>
        {sortedStructures.map(({ type, code, name, structureHasAssignedWards }) => {
          const title = firstCap(structConf[type].displayTitle)
          return (
            <LinkContainer key={`${type}:${code}`} to={`/admin/${type}/${code}/usagereport`}>
              <ListGroup.Item action>
                <span className='text-muted'>{title}:</span>{' '}
                {name}{' '}
                <span className='text-muted'>({code})</span>
              </ListGroup.Item>
            </LinkContainer>
          )
        })}
      </ListGroup>
    </>
  )
}
