import { gql, useQuery } from '@apollo/client'
import { ForbiddenError } from '../components/ErrorBoundary'
import { StructParams, StructureBase, getChildType, QueryStructParams } from '../lib/structures'
import type { Timeline } from '../types/Timeline'

interface ActivistsResult {
  structure: StructureWithActivists
}

interface StructureWithActivists extends StructureBaseWithActivists {
  activists: ActivistsDataSelf
  parents: StructureBase[]
  children: StructureBaseWithActivists[]
}

interface StructureBaseWithActivists extends StructureBase {
  activists: ActivistsDataForChildren
}

interface ActivistsDataForChildren extends StructureBase {
  wardStats: ActivistsWardStats
  current: number
  lastmonth: number
  membership: number
  trainingHeld: number
  untrainedActive: number
}

interface ActivistsWardStats {
  total: number
  milestoneAchieved: number
  trainingHeldAchieved: number
}

interface ActivistsDataSelf extends ActivistsDataForChildren {
  target: number
  historic: Timeline<number>
}

const query = gql`
query getActivistsData($type: String!, $code: String!, $childType: String!) {
  structure(type: $type, code: $code) {
    type
    code
    name
    responsiblePersons { firstname surname displayname }
    userHasAccess
    structureHasAssignedWards
    activists {
      wardStats {
        total
        milestoneAchieved
        trainingHeldAchieved
      }
      current: count(days: 0)
      lastmonth: count(days: 30)
      membership
      target
      trainingHeld
      untrainedActive
      historic {
        date
        count
      }
    }
    parents { type code name userHasAccess }
    children(type: $childType) {
      type
      code
      name
      responsiblePersons { firstname surname displayname }
      userHasAccess
      structureHasAssignedWards
      activists {
        wardStats {
          total
          milestoneAchieved
          trainingHeldAchieved
        }
        current: count(days: 0)
        lastmonth: count(days: 30)
        membership
        trainingHeld
        untrainedActive
      }
    }
  }
}
`

export function useActivists ({ type, code }: StructParams): StructureWithActivists | undefined {
  const childType = getChildType({ type, code })
  const { error, data } = useQuery<ActivistsResult, QueryStructParams>(
    query, { variables: { type, code, childType } }
  )

  if (error?.graphQLErrors[0]?.extensions?.code === 'FORBIDDEN') {
    throw new ForbiddenError('this structure')
  }
  if (error != null) throw error

  return data?.structure
}
