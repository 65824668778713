import type { StructType } from '../lib/structures'
import { PublicityWardStatsField, PublicityActivityCountsField } from '../data/usePublicityData'
import { statsStyle, formatInt, formatPerc } from '../lib/utils'

interface FractionStatProps {
  numerator?: number
  denominator?: number
  structureName?: string
}

export const FractionStat = function ({ numerator, denominator, structureName = 'Wards' }: FractionStatProps): JSX.Element {
  if (numerator == null || denominator == null) return <></>
  return (
    <>
      {formatInt(numerator)} of {formatInt(denominator)} {structureName}
      <br />
      ({formatPerc(numerator / denominator)})
    </>
  )
}

interface BooleanStatProps {
  value: number
  trueValue?: string
  falseValue: string
}

export const BooleanStat = function ({ value, trueValue = 'Met', falseValue }: BooleanStatProps): JSX.Element {
  return (
    <>{(value > 0) ? trueValue : falseValue}</>
  )
}

interface StatCellProps {
  color?: boolean
  structureType: StructType
  numerator: number | undefined
  denominator: number | undefined
  trueText?: string
  falseText?: string
  target?: number
  structureName?: string
}

export function StatCell (props: StatCellProps): JSX.Element {
  const { structureType, numerator, denominator, color = true, trueText = 'On Track', falseText = 'Not On Track', structureName = 'Wards' } = props
  if (numerator == null || denominator == null) return <td />
  return (
    <td style={color ? statsStyle(numerator, denominator) : {}} className='text-center'>
      {structureType === 'ward'
        ? <BooleanStat value={numerator} trueValue={trueText} falseValue={falseText} />
        : <FractionStat numerator={numerator} denominator={denominator} structureName={structureName} />}
    </td>
  )
}

export function PublicityStatCell (props: { publicityWardStatsField: PublicityWardStatsField | undefined }): JSX.Element {
  const { publicityWardStatsField } = props

  if (publicityWardStatsField === undefined) {
    return (<td></td>)
  }

  const { wardsAssigned, wardsTargetAchieved } = publicityWardStatsField

  return (
    <td style={statsStyle(wardsTargetAchieved, wardsAssigned)} className='text-center' >
      <FractionStat numerator={wardsTargetAchieved} denominator={wardsAssigned} />
    </td>
  )
}

export function PublicityActivityCell (props: { publicityActivityCountsField: PublicityActivityCountsField | undefined }): JSX.Element {
  const { publicityActivityCountsField } = props

  if (publicityActivityCountsField === undefined) {
    return (<td></td>)
  }

  const { count, milestone, target } = publicityActivityCountsField

  let backgroundColor: string
  let text: string
  if (count === 0) {
    backgroundColor = '#c00000'
    text = 'Behind Target'
  } else if (count >= target) {
    backgroundColor = '#0070c0'
    text = 'Meeting Target'
  } else if (target !== 1 && count >= milestone) {
    backgroundColor = '#70ad47'
    text = 'On Track to Target'
  } else if (target !== 1 && count > 0) {
    backgroundColor = '#ff7f00'
    text = 'Behind Target'
  } else {
    backgroundColor = '#c00000'
    text = 'Behind Target'
  }

  return (
    <td style={{ fontWeight: 'bold', color: 'white', backgroundColor }} className='text-center'>
      {count} <br /> {text}
    </td>
  )
}

interface UsageReportStatCellProps {
  numerator?: number
  denominator?: number
  structureName?: string
}

export function UsageReportStatCell (props: UsageReportStatCellProps): JSX.Element {
  const { numerator, denominator, structureName = 'Wards' } = props
  if (numerator == null || denominator == null) return <></>
  return (
    <td style={statsStyle(numerator, denominator)} className='text-center'>

      {formatInt(numerator)} of {formatInt(denominator)} {structureName}  ({formatPerc(numerator / denominator)})
    </td>
  )
}
