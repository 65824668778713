import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import useUserInfo from '../data/useUserInfo'
import useAdminStructures from '../data/useAdminStructures'

const MainNav = (): JSX.Element => {
  const userInfo = useUserInfo()
  const userAdminStructures = useAdminStructures()
  return (
    <>
      <Navbar bg='primary' variant='dark'>
        <Link to='/'>
          <img src='https://static.da-io.net/images/da-logo.png' alt='DA logo' height='38' />
        </Link>
        <LinkContainer to='/'>
          <Navbar.Brand>
            My DA, My Branch Dashboard
          </Navbar.Brand>
        </LinkContainer>
      </Navbar>
      <Nav className='sub-nav'>
        <LinkContainer to='/'><Nav.Link>Home</Nav.Link></LinkContainer>
        {(userAdminStructures && userAdminStructures.length > 0) && (
          <Nav.Item>
            <LinkContainer to='/admin'><Nav.Link>Management Reports</Nav.Link></LinkContainer>
          </Nav.Item>
        )}
        {userInfo != null && (
          <>
            <Nav.Item className='ml-auto'>
              <Nav.Link disabled>Logged in as {userInfo.firstname} {userInfo.surname}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='https://login.voteda.org/logout'>Logout</Nav.Link>
            </Nav.Item>
          </>
        )}
      </Nav>
    </>
  )
}
export default MainNav
